/* === Main Style === */

.main-menu {
  position: relative;
  height: 100%;
  padding-block: 30px;
  display: flex;
  gap: 30px;
  transition: width 0.3s ease-in-out;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--black-100);
  border-radius: 0px;
  background: linear-gradient(
    180deg,
    var(--blue-100) 35%,
    var(--blue-100) 100%
  );
  .menu-top {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .app-logo {
      display: flex;
      padding: 0px 30px 30px 30px;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid var(--white-100-10);
    }
    .c-nav {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      padding-inline-end: 20px;
      color: var(--white-100);
      .span-category {
        font-weight: bold;
        padding-inline: 20px;
      }
      .c-nav-ul {
        list-style-type: none;
        margin-block: 0;
        padding-inline: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        width: 100%;
      }
    }
  }
  .menu-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-inline: 20px;
    .menu-text-bottom {
      opacity: 0.25;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      font-style: italic;
    }
  }
}

.menu-link {
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  &:last-child {
    margin-bottom: 20px;
    &:before {
      height: 100%;
    }
  }
  .menu-sub-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 0px 30px 30px 0px;
    color: var(--white-100);
    transition: all 0.3s;
    .icon-menu {
      filter: invert(1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

/* === Media Query === */

@media screen and (max-width: 1440px) {
  .main-menu.user-role {
    display: none;
  }
  .main-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding-block: 12px;
    z-index: 999;
    border-radius: 20px 20px 0px 0px;
    .menu-top {
      width: 100%;
      .app-logo {
        display: none;
      }
      .c-nav {
        padding-inline: 20px;
        flex-direction: row;
        span {
          display: none;
        }
        .c-nav-ul {
          flex-direction: row;
          justify-content: space-between;
          .menu-link {
            width: fit-content;
            .menu-sub-item {
              justify-content: center;
              border-radius: 50%;
              padding: 10px;
              width: 45px;
              border: 1px solid rgba(255, 255, 255, 0.2);
              height: 45px;
              aspect-ratio: 1/1;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .menu-bottom {
      display: none;
    }
  }

  .main-app-right-part {
    border-radius: 0px;
    padding-bottom: 85px;
    overflow-y: scroll;
  }

  .user-item {
    width: fit-content;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-title {
    display: none;
  }
  .user-item {
    span {
      display: none;
    }
  }
}
