@import url(Common.scss);
@import url(Fonts.scss);
@import url(CustomTag.scss);

.input {
  height: 2.5rem;
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
  padding: 0 1.25rem 0 2.5rem;
  position: relative;

  &:focus-visible {
    outline: transparent;
  }
}

textarea:focus-visible {
  outline: transparent;
}

.c-section {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .c-section-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 5px;
  }
}

.grid .main-router-content {
  grid-area: 2 / 1 / 2 / 3;
  height: calc(100vh - 7.1594rem);
  overflow-y: scroll;
  overflow-x: hidden;
}

.main-router-content {
  .view-container {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }
}

.input-date-container {
  display: flex;
}

.view-container .input-date-container .waiting-order-warning {
  margin-bottom: 0;
}

.portal-content,
.test-content {
  height: calc(100vh - 6.25rem);
}

.input-container-search:before,
.input-address-container:before {
  content: "";
  position: absolute;
  left: 0.75rem;
  top: 0.6875rem;
  width: 1.125rem;
  height: 1.375rem;
  background: url("../icons/search.svg") no-repeat;
  background-size: contain;
  z-index: 1;
}

.list-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-header_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.view-header .c-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.c-intro {
  background: var(--blue-5);
  padding: 10px;
  color: #1678c5;
  border-radius: 1rem;
  font-weight: 500;
  margin: 1.25rem 0 0 0;
  width: auto;
}

.without-top {
  padding-top: 0;
}

.header-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-input {
  width: 100%;

  input {
    font-size: 2em;
    font-weight: bold;
    border: 0;
    width: 100%;
    padding: 0;
    height: 2.5rem;

    &::placeholder {
      color: var(--black-15);
    }
  }
}

.admin-select-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
  border-radius: 1rem;

  h1 {
    font-size: 1.8em;
    font-weight: bold;
    position: relative;
    margin-bottom: 0;
  }
}

.navbar-title {
  h1 {
    font-size: clamp(1.25rem, 1.1429rem + 0.5357vw, 1.5rem);
    font-weight: bold;
    position: relative;
    margin-bottom: 0;
    color: var(--blue-110);
  }
}

.header-title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h2,
  h3 {
    font-size: clamp(1.25rem, 1.1786rem + 0.3571vw, 1.5rem);
    font-weight: bold;
    padding-left: 2.25rem;
    position: relative;
    color: var(--blue-110);

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.home h2::before {
    background: url("../icons/home--blue.svg") no-repeat;
    background-size: contain;
  }

  &.properties h2::before {
    background: url("../icons/home--blue.svg") no-repeat;
    background-size: contain;
  }

  &.parts h2:before {
    background: url("../icons/usd-circle--yellow.svg") no-repeat;
    background-size: contain;
  }

  &.macro h2:before {
    background: url("../icons/statistics-full--blue.svg") no-repeat;
  }

  &.analytics h2:before {
    background: url("../images/google-analytics-logo.png") no-repeat;
    background-size: contain;
  }

  &.ads h2:before {
    background: url("../images/google-adwords-logo.png") no-repeat;
    background-size: contain;
  }

  &.customers h2:before {
    background: url("../icons/users--blue.svg") no-repeat;
    background-size: contain;
  }

  &.meta h2 {
    padding-left: 7rem;
  }

  &.meta h2::before {
    width: 6.25rem;
    height: 1.875rem;
    background: url("../images/meta.png") no-repeat;
    background-size: contain;
  }

  &.stats-livie h2:before {
    background: url("../icons/statistics-full--blue.svg") no-repeat;
    background-size: contain;
  }

  &.chat-livie h3:before {
    background: url("../images/logo-livie.svg") no-repeat;
    background-size: contain;
  }

  &.gestion-hotel h2:before {
    background: url("../icons/gestion-hotel--blue.svg") no-repeat;
    background-size: contain;
  }

  &.users h2:before {
    left: 0.1875rem;
    top: 0.1875rem;
    width: 1.5rem;
    height: 1.5rem;
    background: url("../icons/account.svg") no-repeat;
    background-size: contain;
  }

  p {
    color: var(--blue-110);
    font-size: 0.9rem;
    font-style: italic;
    padding-left: 2.25rem;
  }
}

.header-title.providers h1:before {
  width: 2.25rem;
  height: 2.25rem;
  background: url("../icons/supplier--blue.svg") no-repeat;
  background-size: contain;
}

.header-title.blank h2 {
  padding-left: 0;
}

.header-title.blank h2:before {
  background: unset;
}

.main-button,
.small-button {
  background: var(--white-100);
  border: 1px solid var(--black-15) !important;
  height: 2.5rem;
  padding: 0 1.25rem 0 3.125rem;
  font-weight: 500;
  border-radius: 10px;
  position: relative;
  margin-bottom: 0;
  transition: all 0.3s;
  cursor: pointer;
}

.squared-button {
  background: var(--white-100);
  border: 2px solid var(--black-10);
  height: 2.25rem;
  width: 2.375rem;
  font-weight: 500;
  border-radius: 1.25rem;
  position: relative;
  transition: all 0.3s;

  &:before {
    content: "";
    position: absolute;
    left: 0.5625rem;
    top: 0.9375rem;
    width: 1.125rem;
    height: 1.375rem;
    background: url("../icons/options--gray.svg") no-repeat;
    background-size: contain;
    transition: all 0.3s;
  }

  &:hover {
    background: var(--black-15);
  }
}

.input-container .squared-button {
  margin-left: 10px;
}

.squared-button.blue-button {
  background: var(--blue-100);

  &:before {
    width: 1.0625rem;
    top: 0.5625rem;
    background: url("../icons/plus-white.svg") no-repeat;
    background-size: contain;
  }

  &:hover {
    background: var(--blue-120);
  }
}

.main-button.warning-button {
  border: 0.0625rem solid var(--red-30);
  color: var(--red-100);
}

.main-button.warning-button:hover {
  border: 0.0625rem solid var(--red-100);
  background: var(--red-100);
  color: var(--white-100);
}

.main-button.without-icon {
  padding: 0 1.25rem;
}

.main-button.without-icon:before {
  display: none;
}

.input-group {
  padding: 1.875rem;
}

.input-group.without-top {
  padding: 0 1.875rem 1.875rem 1.875rem;
}

.input-container.transaction-mapper,
.single-search-input.invoice-search-input {
  width: calc(100% - 1rem);
}

.single-search-input.invoice-search-input .select__control {
  min-height: 2.375rem;
}

.input-container.transaction-mapper:before {
  top: 10px;
}

.input-container,
.input-container-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.input-container input,
.input-container-search input {
  width: 100%;
}

.input-container.without-icon:before {
  display: none;
}

.input-labelNaNpxinder:before {
  background: url("../icons/reminder--blue.svg") no-repeat;
  background-size: contain;
  height: 1.3125rem;
  top: 0.0625rem;
}

.input-label {
  font-weight: 500;
  width: 12.5rem;
  padding-left: 2.1875rem;
  position: relative;

  &.user {
    &:before {
      background: url("../icons/account.svg") no-repeat;
      background-size: contain;
      height: 1.375rem;
    }
  }

  &.provider {
    &:before {
      background: url("../icons/supplier--blue.svg") no-repeat;
      background-size: contain;
      height: 1.4375rem;
      top: 0.125rem;
    }
  }

  &.file {
    &:before {
      background: url("../icons/file--blue.svg") no-repeat;
      background-size: contain;
      height: 1.125rem;
      top: 0.25rem;
    }
  }

  &.phone {
    &:before {
      background: url("../icons/phone--blue.svg") no-repeat;
      background-size: contain;
      height: 1.125rem;
      top: 0.1875rem;
    }
  }

  &.location {
    &:before {
      background: url("../icons/address--blue.svg") no-repeat;
      background-size: contain;
      height: 1.375rem;
    }
  }

  &.reminder {
    &:before {
      background: url("../icons/reminder--blue.svg") no-repeat;
      background-size: contain;
      height: 1.3125rem;
      top: 0.0625rem;
    }
  }

  &.order {
    &:before {
      background: url("../icons/order--blue.svg") no-repeat;
      background-size: contain;
      height: 1.25rem;
      left: 0.25rem;
      top: 0.0625rem;
    }
  }

  &.type {
    &:before {
      background: url("../icons/type--blue.svg") no-repeat;
      background-size: contain;
      height: 1.125rem;
      top: 0.1875rem;
    }
  }

  &.email {
    &:before {
      background: url("../icons/message--blue.svg") no-repeat;
      background-size: contain;
      height: 0.9375rem;
      top: 0.3125rem;
    }
  }

  &.role {
    &:before {
      background: url("../icons/status--blue.svg") no-repeat;
      background-size: contain;
      height: 1.1875rem;
      top: 0.1875rem;
    }
  }

  &.calendar {
    &:before {
      background: url("../icons/calendar-blue.svg") no-repeat;
      background-size: contain;
      height: 1.125rem;
      top: 0.125rem;
    }
  }

  &.pen {
    &:before {
      background: url("../icons/pen-blue.svg") no-repeat;
      background-size: contain;
      height: 1.25rem;
      top: 0.0625rem;
    }
  }

  &.folder {
    &:before {
      background: url("../icons/folder-blue.svg") no-repeat;
      background-size: contain;
      height: 1rem;
      top: 0.25rem;
    }
  }
}

.input-row-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.input-row-container.align-columns {
  flex-direction: column;
  align-items: flex-start;
}

.input-row-container.align-columns .input-container {
  width: 100%;
}

.input-row-container.align-columns .input-label {
  margin-bottom: 0.9375rem;
}

.input-textarea-container,
.input-orders-container {
  width: 100%;
}

.input-orders-container .list-container {
  height: unset;
  font-weight: 500;
}

.input-orders-container .list-container tbody tr td:first-child {
  padding-left: 1.875rem;
}

.input-orders-container .list-container thead th:first-child {
  padding-left: 3.375rem;
}

.input-orders-container .list-container .column-icon:first-child:before {
  left: 1.875rem;
}

.input-textarea-container:last-child {
  margin-bottom: 0;
}

.list-container tbody tr:last-child {
  border-bottom: 0;
}

.input-textarea textarea,
.textarea-view {
  width: 100%;
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
  padding: 0.625rem 0.9375rem;
  position: relative;
  margin-top: 1.25rem;
  resize: none;
}

.textarea-view {
  min-height: 10rem;
  white-space: pre-line;
}

.main-button:hover,
.small-button:hover,
.mapper-button:hover {
  border: 0.0625rem solid var(--blue-60);
  background: var(--blue-20);
  color: var(--blue-100);
}

.main-button.blue-button {
  background: var(--blue-100);
  border: 0;
  color: var(--white-100);
  font-weight: 600;
}

.mapper-button {
  background: var(--blue-100);
  color: var(--white-100);
  font-weight: 600;
  height: 1.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding-left: 1.625rem;
  transition: all 0.3s;
  border: 0.0625rem solid transparent;
  position: relative;
}

.mapper-button:hover:before {
  background: url("../icons/plus-blue.svg") no-repeat;
  background-size: contain;
}

.mapper-button:before {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  background: url("../icons/plus-white.svg") no-repeat;
  background-size: contain;
}

.main-button.green-button {
  background: var(--green-100);
  border: 0;
  color: var(--white-100);
  font-weight: 600;
}

.main-button.green-button:hover {
  background: var(--green-170);
}

.main-button.red-button:hover {
  background: var(--red-170);
}

.main-button.red-button {
  background: var(--red-100);
  border: 0;
  color: var(--white-100);
  font-weight: 600;
}

.main-button.blue-button:disabled,
.main-button.blue-button:disabled:hover {
  background: var(--blue-20);
}

.main-button.blue-button:hover {
  background: var(--blue-120);
}

.main-button.blue-button:before {
  background: url("../icons/plus-white.svg") no-repeat;
  background-size: contain;
  width: 1.125rem;
  left: 1.0625rem;
  transition: all 0.3s;
}

.main-button.blue-button.launch:before {
  background: url("../icons/launch--white.svg") no-repeat;
  background-size: contain;
  width: 1.25rem;
}

.main-button.red-button.close-button:before {
  background: url("../icons/close--white.svg") no-repeat;
  background-size: contain;
  width: 0.9375rem;
  left: 1.125rem;
  top: 0.8125rem;
}

.main-button.blue-button.launch:hover:before {
  background: url("../icons/launch--white.svg") no-repeat;
  background-size: contain;
}

.main-button.blue-button.edit:before {
  background: url("../icons/edit--white.svg") no-repeat;
  background-size: contain;
  width: 0.9375rem;
  left: 1.1875rem;
  top: 0.75rem;
}

.main-button.blue-button.edit:hover:before {
  background: url("../icons/edit--white.svg") no-repeat;
  background-size: contain;
}

.main-button.blue-button.validate:before,
.main-button.green-button.validate:before {
  background: url("../icons/check--white.svg") no-repeat;
  background-size: contain;
  width: 1.0625rem;
  left: 1.125rem;
  top: 0.9375rem;
}

.main-button.blue-button.validate:hover:before,
.main-button.green-button.validate:hover:before {
  background: url("../icons/check--white.svg") no-repeat;
  background-size: contain;
}

.main-button.blue-button:hover:before {
  background: url("../icons/plus-white.svg") no-repeat;
  background-size: contain;
}

.main-button:hover:before,
.small-button:hover:before {
  background: url("../icons/filter-blue.svg") no-repeat;
  background-size: contain;
}

.main-button.download:hover:before {
  background: url("../icons/download-full--black.svg") no-repeat;
  background-size: contain;
}

.main-button:before,
.small-button:before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 0.6875rem;
  width: 1.0625rem;
  height: 1.375rem;
  background: url("../icons/filter.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.main-button.download:before {
  background: url("../icons/download.svg") no-repeat;
  background-size: contain;
}

.main-button.validate:before {
  background: url("../icons/check--black.svg") no-repeat;
  background-size: contain;
  top: 0.875rem;
}

.main-button.validate:hover:before {
  background: url("../icons/check--blue.svg") no-repeat;
  background-size: contain;
}

.main-button.delete:before {
  background: url("../icons/delete--red.svg") no-repeat;
  background-size: contain;
}

.main-button.refresh:before {
  background: url("../icons/refresh--black.svg") no-repeat;
  background-size: contain;
}

.main-button.refresh:hover:before {
  background: url("../icons/refresh--blue.svg") no-repeat;
  background-size: contain;
}

.main-button.up:before {
  background: url("../icons/arrow-toggle.svg") no-repeat;
  background-position: 50% 40%;
  transition: 0.3s ease-in;
  transform: rotate(0);
}

.main-button.down:before {
  background: url("../icons/arrow-toggle.svg") no-repeat;
  background-position: 50% 70%;
  transition: 0.3s ease-in;
  transform: rotate(180deg);
}

.main-button.delete:hover:before {
  background: url("../icons/delete--white.svg") no-repeat;
  background-size: contain;
}

.main-button.clients:before {
  background: url("../icons/status--black.svg") no-repeat;
  background-size: contain;
}

.main-button.archive:before {
  background: url("../icons/archive--black.svg") no-repeat;
  background-size: contain;
  width: 1.3125rem;
}

.main-button.archive:hover:before {
  background: url("../icons/archive--blue.svg") no-repeat;
  background-size: contain;
}

.main-button.small-button.download:before {
  top: 0.5625rem;
  left: 0.6875rem;
}

#multi-select .MuiInputBase-formControl {
  width: 12.5rem !important;
}

.search-list {
  position: absolute;
  background: var(--white-100);
  width: 100%;
  top: 2.5rem;
  border-radius: 0 0 0.75rem 0.75rem;
  border: 0.0625rem solid var(--black-15);
  border-top: 0;
  max-height: 19.125rem;
  overflow: hidden;
}

.search-list.hidden {
  display: none;
}

.input-container-search input:focus {
  border-radius: 0.75rem 0.75rem 0 0;
}

.search-list span {
  font-size: 0.8em;
  padding: 10px;
  display: block;
  font-weight: 500;
  color: var(--black-70);
}

.custom-tag,
.single-search-input .select__single-value,
.multi-search-input .select__multi-value {
  font-weight: 600;
  color: var(--blue-120);
  background: var(--blue-20);
  padding: 0.125rem 0.5625rem;
  width: fit-content;
  border-radius: 0.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.header-title.macro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.macro-selector {
  display: flex;
  gap: 10px;
}

.header-title.macro .searchbar-container {
  width: auto;
}

.header-title.macro .searchbar-container .MuiFormControl-root {
  width: 9.375rem !important;
}

.macro_list {
  overflow-y: visible !important;
}

.macro_list th,
.macro_list td {
  text-align: center;
}

.macro_list td:nth-child(3),
.macro_list td:nth-child(2),
.macro_list td:nth-child(1),
.macro_list th:nth-child(3),
.macro_list th:nth-child(2),
.macro_list th:nth-child(1) {
  text-align: left !important;
}

.hotel-name {
  width: 25%;
}

th.analytics,
th.ads,
th.meta {
  position: relative;
}

th.analytics-bg {
  background: var(--red-30);
}

th.ads-bg {
  background: var(--yellow-30);
}

th.meta-bg {
  background: var(--green-30);
}

th.analytics:after,
th.ads:after,
th.meta:after {
  content: "Analytics";
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  background: var(--red-30);
  color: var(--red-170);
  font-size: 0.8125rem;
  padding: 0.125rem 0.5625rem;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

th.ads:after {
  content: "Ads";
  background: var(--yellow-30);
  color: var(--yellow-100);
}

th.meta:after {
  content: "Meta";
  background: var(--green-30);
  color: var(--green-100);
}

.multi-search-input .select__multi-value {
  margin: 0.1875rem 0.3125rem 0.1875rem 0;
  padding: 0.125rem 0.125rem 0.125rem 0.5625rem;
}

.multi-search-input .select__multi-value__remove {
  padding: 0.125rem;
  border-radius: 10px;
  margin: 0 0.125rem;
  cursor: pointer;
}

.multi-search-input .select__multi-value__label {
  color: var(--blue-120);
  padding: 0;
  font-size: 1em;
}

.invoice-search-input .select__input {
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.invoice-search-input .select__value-container--has-value .select__input-container {
  margin: 0;
  position: absolute;
  opacity: 0;
}

.single-search-input.invoice-search-input .select__value-container,
.multi-search-input.invoice-search-input .select__value-container {
  padding: 0.1875rem 0.375rem;
}

.single-search-input .select__value-container.select__value-container--has-value div:last-child {
  margin: 0;
}

.searchbar-container .single-search-input .select__single-value {
  top: 1.5625rem;
}

.selected-tag {
  position: absolute;
  z-index: 10;
}

.hidden {
  display: none;
}

.single-search-input,
.multi-search-input {
  width: 100%;
  border-radius: 10px;
}

.single-search-input .select__control,
.multi-search-input .select__control {
  border-radius: 10px;
  min-height: 2.5rem;
  cursor: pointer;
  border: 1px solid var(--black-15);
  background-color: var(--black-10);
}

.searchbar-container .select__control,
.searchbar-container .select__value-container {
  height: 2.5rem;
}

.searchbar-item input {
  padding: 0 0 0 1.25rem !important;
  background: transparent !important;
  border: none !important;
}

.single-search-input .select__menu,
.multi-search-input .select__menu {
  z-index: 11;
  padding: 8px;
  background: var(--white-100);
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.875rem rgba(0, 0, 0, 0.1);
}

.single-search-input .select__menu-list::-webkit-scrollbar,
.multi-search-input .select__menu-list::-webkit-scrollbar {
  display: none;
}

.single-search-input .select__menu-list,
.multi-search-input .select__menu-list {
  padding: 0;
  -ms-overflow-style: none;
}

.list-container {
  width: 100%;
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
  overflow-x: scroll;
}

table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 1rem;
  font-size: 0.9em;

  thead {
    background-color: var(--black-10);
    color: var(--black-100);

    th {
      padding: 0.625rem 0.9375rem;
      text-align: left;
      font-weight: bold;
    }
  }

  tbody {
    tr {
      background-color: var(--white-100);

      &:not(:last-child) {
        border-bottom: 0.0625rem solid var(--black-intermediate);
      }

      // &:nth-child(even) {
      //   background-color: var(--black-10);
      // }
      td {
        padding: 0.625rem;

        &:last-child {
          padding-right: 1.875rem;
        }
      }
    }
  }
}

.transactions-container.properties {
  .list-container {
    table {
      tbody {
        tr {
          &:hover {
            background-color: var(--black-intermediate);
          }
        }
      }
    }
  }
}

.border-item {
  background: var(--blue-100);
  color: var(--white-100);
  font-weight: 600;
  padding: 0.0625rem 0.4375rem;
  width: fit-content;
  border-radius: 0.5625rem;
}

.list-container tbody tr {
  border-bottom: 0.0625rem solid var(--black-15);
  transition: all 0.3s;
}

.loader-container {
  padding: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: 100dvh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.pagination-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 0.9em;
  color: var(--black-70);
  font-weight: 500;
}

.checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1.875rem;
  height: 2.8125rem;
}

.checkbox-container.pastille-checkbox-div {
  padding-left: 2.8125rem;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0.8125rem;
  left: 0.8125rem;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.4375rem;
  background: var(--black-15);
  border: 0.0625rem solid var(--black-15);
  transition: all 0.2s;
}

.checkbox-container:hover input~.checkmark {
  background-color: #efefef;
}

.checkbox-container input:checked~.checkmark {
  background-color: var(--blue-100);
  border: 0.0625rem solid var(--blue-100);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 0.25rem;
  top: 0.25rem;
  width: 10px;
  height: 10px;
  background: url("../icons/white-smooth-check.svg") no-repeat;
  background-size: cover;
  transition: all 0.2s;
}

.page-switcher {
  display: flex;
  gap: 5px;
}

.pagination-button {
  border: 0.0625rem solid var(--black-15);
  background: var(--white-100);
  border-radius: 10px;
  width: 2rem;
  height: 2rem;
  font-weight: 600;
  color: var(--black-70);
  padding: 0;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--black-10);
    border: 0.0625rem solid var(--black-30);
  }

  &:disabled {
    border: 0.0625rem solid var(--black-15);
    color: var(--black-15);
  }
}

.pagination-button.select {
  width: fit-content;
}

.pagination-button .pagination-button:disabled:hover {
  background: var(--white-100);
  border: 0.0625rem solid var(--black-15);
  color: var(--black-15);
}

.padding-right {
  padding-right: 10px;
}

.list-container th {
  position: relative;
}

.sorted,
.sorted-desc {
  position: relative;
}

.sorted:before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  background: url("../icons/sort-ascendant--gray.svg") no-repeat;
  background-size: contain;
}

.sorted-desc:before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  background: url("../icons/sort-descendant--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon {
  padding-left: 2.125rem;
  position: relative;
  text-wrap: nowrap;
}

.list-container .column-icon:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background: url("../icons/supervisor--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.email:before {
  background: url("../icons/project--gray.svg") no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
}

.list-container .column-icon.pen:before {
  background: url("../icons/name--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.country:before {
  background: url("../icons/flag--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.view:before {
  background: url("../icons/eye--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.clock:before {
  background: url("../icons/clock--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.bounce:before {
  background: url("../icons/bounce--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.euro:before {
  background: url("../icons/euro--grey.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.id:before {
  background: url("../icons/id--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.customer:before {
  background: url("../icons/customer--gray.svg") no-repeat;
  background-size: contain;
  height: 1.0625rem;
  width: 1.0625rem;
}

.list-container .column-icon.calendar:before {
  background: url("../icons/calendar--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.order:before {
  background: url("../icons/order--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.role:before {
  background: url("../icons/status--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.type:before {
  background: url("../icons/type--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.conversation:before {
  background: url("../icons/conversation-full--gray.svg") no-repeat;
  background-size: contain;
}

.list-container .column-icon.provider:before {
  background: url("../icons/supplier--gray.svg") no-repeat;
  background-size: contain;
  height: 1.125rem;
  width: 1.125rem;
}

.list-container .column-icon.project:before {
  background: url("../icons/project--gray.svg") no-repeat;
  background-size: contain;
  top: 1.125rem;
  width: 1rem;
}

.list-container a {
  text-decoration: none;
  color: var(--black-100);
  font-weight: 500;
}

.view-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1.875rem;
}

.view-title h1,
.custom-modal-header h1 {
  font-size: 2em;
  font-weight: 800;
  position: relative;
  margin-bottom: 0;
  padding-right: 1.25rem;
}

.portal-title h1,
h1.order-portal-welcome,
.test-content h1 {
  font-size: 1.5em;
  font-weight: 800;
  position: relative;
  margin-bottom: 0;
}

.view-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-left {
  margin-left: 0.625rem;
}

.margin-right {
  margin-right: 0.625rem;
}

.margin-bottom-15 {
  margin-bottom: 0.9375rem;
}

.view-title .view-id,
.portal-title span,
.view-header .view-id {
  color: var(--black-100);
  font-weight: 600;
  display: block;
  padding-top: 0.3125rem;
}

.classic-input,
.search-input,
.contact-container {
  height: 2.5rem;
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
  padding: 0 0.9375rem;
}

.search-input {
  padding-left: 2.5rem;
}

.input-required,
.input-required:focus-visible {
  border: 0.125rem solid var(--red-100);
  outline: transparent;
}

.relative {
  position: relative;
}

.input-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-date-container .react-datepicker {
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
}

.input-date-container .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: var(--black-15);
}

.input-date-container .react-datepicker__header {
  border-top-left-radius: 0.75rem;
  border-bottom: 0.0625rem solid var(--black-15);
}

.input-date-container .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.75rem;
}

.input-date-container .react-datepicker__year-read-view--down-arrow,
.input-date-container .react-datepicker__month-read-view--down-arrow,
.input-date-container .react-datepicker__month-year-read-view--down-arrow,
.input-date-container .react-datepicker__navigation-icon::before {
  top: 0.75rem;
}

input.name-input-required::placeholder {
  color: var(--red-30);
}

.input-date-container .date-view,
.input-classic-container {
  height: 2.5rem;
  border: 0.0625rem solid var(--black-15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-radius: 1rem;
  font-weight: 500;
}

.input-classic-container {
  width: 100%;
  height: 3.125rem;
  border-radius: 1rem;
  padding: 0 1.25rem;
  justify-content: left;
}

th.checkbox-fixed-size {
  width: 3.75rem;
}

.main-button.small-button {
  height: 2.1875rem;
  padding: 0 0.75rem 0 2.1875rem;
}

.main-button.small-button.without-icon {
  padding: 0 0.75rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

label.main-button.small-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-button.small-button.blue-button:before {
  left: 10px;
  top: 0.5625rem;
}

.delete-icon-button {
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.9375rem;
  border: 0;
  background: var(--white-100);
  transition: all 0.3s;

  &:hover {
    background: var(--red-30);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0.4375rem;
    top: 0.375rem;
    width: 1.0625rem;
    height: 1.0625rem;
    background: url("../icons/delete-full--red.svg") no-repeat;
    background-size: contain;
  }
}

.contact-container .delete-icon-button {
  top: 10px;
}

.update-icon-button {
  position: absolute;
  top: 0.6875rem;
  right: 2.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.9375rem;
  border: 0;
  background: var(--white-100);
  transition: all 0.3s;

  &::before {
    content: "";
    position: absolute;
    left: 0.4375rem;
    top: 0.375rem;
    width: 1.0625rem;
    height: 1.0625rem;
    background: url("../icons/pen-blue.svg") no-repeat;
    background-size: contain;
  }
}

.custom-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal.hidden {
  display: none;
}

.custom-modal-container {
  width: 37.5rem;
  min-height: 45vh;
  background: var(--white-100);
  border-radius: 1.25rem;
  padding: 1.875rem;
}

.custom-modal-header {
  border-bottom: 0.0625rem solid var(--black-15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 1.25rem;
}

.custom-modal-header .modal-close {
  width: 2rem;
  height: 2rem;
  border-radius: 0.9375rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  transform: rotate(45deg);
  border: 0;
  background: transparent;
  transition: all 0.3s;
}

.custom-modal-header .modal-close:hover {
  background: var(--black-15);
}

.submit-right-container {
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact-container {
  width: 100%;
  height: 3.125rem;
  display: flex;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.9375rem;
  position: relative;
  padding: 0 4.6875rem 0 1.25rem;
  font-weight: 500;
}

.update-icon-button:hover {
  background: var(--blue-5);
}

.contact-container.full-width {
  padding: 0 1.25rem;
}

.contact-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
  }
}

.input-address-container {
  font-weight: 500;
  width: 100%;
  border: 0.0625rem solid var(--black-15);
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  border-radius: 1rem;
  position: relative;

  &::before {
    top: 0.9375rem;
  }
}

.no-value {
  font-weight: 500;
  width: 100%;
  background: var(--red-30);
  border: 0.0625rem solid var(--red-100);
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding: 0 0.9375rem;
  border-radius: 1rem;
  position: relative;
}

.provider-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.provider-block {
  width: 42px;
  height: 7.5rem;
  margin-right: 1.125rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.provider-block.fabrics:hover,
.provider-block.fabrics.checked {
  background: var(--purple-30);
  border: 0.0625rem solid var(--purple-60);
}

.provider-block.fabrics:hover .provider-check,
.provider-block.fabrics.checked .provider-check {
  border: 0.0625rem solid var(--purple-60);
}

.provider-block .provider-check:before {
  content: "";
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 1rem;
  height: 1rem;
  background: transparent;
  border-radius: 10px;
  transition: all 0.3s;
}

.provider-block.fabrics.checked .provider-check:before {
  background: var(--purple-100);
}

.provider-block.production.checked .provider-check:before {
  background: var(--orange-100);
}

.provider-block.logistics.checked .provider-check:before {
  background: var(--blue-100);
}

.provider-block.production:hover,
.provider-block.production.checked {
  background: var(--orange-30);
  border: 0.0625rem solid var(--orange-60);
}

.provider-block.production:hover .provider-check,
.provider-block.production.checked .provider-check {
  border: 0.0625rem solid var(--orange-60);
}

.provider-block.logistics:hover,
.provider-block.logistics.checked {
  background: var(--blue-30);
  border: 0.0625rem solid var(--blue-60);
}

.provider-block.logistics:hover .provider-check,
.provider-block.logistics.checked .provider-check {
  border: 0.0625rem solid var(--blue-60);
}

.provider-check {
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 0.6875rem;
  border: 0.0625rem solid var(--black-15);
  top: 0.75rem;
  right: 0.75rem;
  background: var(--black-15);
  transition: all 0.3s;
}

.provider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;

  img {
    height: 1.625rem;
    margin-bottom: 10px;
  }
}

.production .provider-content img {
  height: 1.875rem;
}

#file-add {
  width: 0.0625rem;
  height: 0.0625rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-block {
  height: 11.875rem;
  width: 12.5rem;
  margin-right: 1.25rem;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.08);
  display: inline-block;
  margin-bottom: 1.25rem;
}

.file-preview {
  height: 26px;
  width: 100%;
  border-radius: 0.75rem 0.75rem 0 0;
  overflow: hidden;
}

.file-info {
  height: 5rem;
  border-radius: 0 0 0.75rem 0.75rem;
  position: relative;
}

.file-info span {
  width: calc(100% - 0.625rem);
  display: block;
  padding: 0.3125rem 10px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}

.file-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.file-buttons a {
  height: 1.875rem;
  display: flex;
}

.file-button {
  width: 2.5rem;
  height: 1.875rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid var(--blue-30);
  background: var(--white-100);
  margin-left: 0.3125rem;
  position: relative;
  transition: all 0.3s;
}

.file-button:hover {
  border: 0.0625rem solid var(--blue-100);
  background: var(--blue-100);
}

.file-button.view:hover:before {
  background: url("../icons/see-more--white.svg") no-repeat;
  background-size: contain;
}

.file-button.delete:hover:before {
  background: url("../icons/delete-full--white.svg") no-repeat;
  background-size: contain;
}

.file-button.delete:hover {
  border: 0.0625rem solid var(--red-100);
  background: var(--red-100);
}

.file-button.delete {
  border: 0.0625rem solid var(--red-30);
}

.file-button.view:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.5625rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url("../icons/see-more--blue.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.file-button.download:before {
  content: "";
  position: absolute;
  top: 0.375rem;
  left: 0.75rem;
  width: 1rem;
  height: 1rem;
  background: url("../icons/download-full--blue.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.file-button.download:hover:before {
  background: url("../icons/download-full--white.svg") no-repeat;
  background-size: contain;
}

.file-button.delete:before {
  content: "";
  position: absolute;
  top: 0.375rem;
  left: 0.6875rem;
  width: 1rem;
  height: 1rem;
  background: url("../icons/delete-full--red.svg") no-repeat;
  background-size: contain;
}

.files-container {
  margin-bottom: 1.25rem;
  width: 100%;
}

.custom-page-file canvas {
  width: 12.5rem !important;
  height: auto !important;
  min-height: 6.875rem !important;
}

.file-preview img {
  width: 12.5rem;
  height: auto;
  min-height: 26px;
}

.scrollable-page {
  overflow-y: scroll;
}

.scrollable-actions {
  overflow-y: scroll;
  height: calc(100% - 7.1875rem);
}

.frequency-container {
  font-weight: 500;
}

.frequency-container input,
.frequency-container select {
  margin-left: 10px;
  height: 2.1875rem;
  border-radius: 10px;
  border: 0.0625rem solid var(--black-15);
  padding: 0 10px;
}

.flex-view {
  height: 100%;
  display: flex;
  justify-content: center;
}

.flex-view .left-container,
.portal-content .left-container {
  width: 60%;
}

.flex-view .right-container,
.portal-content .right-container {
  width: 40%;
  border-left: 0.0625rem solid var(--black-15);
  position: relative;
}

.order-portal .flex-view .right-container {
  height: calc(100% - 6.25rem);
}

.action-date {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--black-50);
  text-align: center;
  padding: 0.375rem 0;
}

.action-block {
  padding: 0.375rem 0;
}

.custom-action:first-child {
  padding-top: 0.375rem;
}

.custom-action:last-child {
  padding-bottom: 0.375rem;
}

.action-block.message,
.order-portal .action-block.provider-message {
  display: flex;
  padding-right: 1.5625rem;
  justify-content: flex-end;
}

.action-block.provider-message,
.order-portal .action-block.message {
  display: flex;
  padding-left: 1.5625rem;
  justify-content: flex-start;
}

.action-block.provider-message span,
.order-portal .action-block.message span {
  white-space: pre-line;
  display: block;
  width: 75%;
  background: var(--black-15);
  padding: 0.9375rem;
  border-radius: 1rem;
  font-weight: 500;
  font-size: 0.92em;
  color: var(--black-100);
}

.action-block.message span,
.order-portal .action-block.provider-message span {
  white-space: pre-line;
  display: block;
  width: 75%;
  background: var(--blue-100);
  color: white;
  padding: 0.9375rem;
  border-radius: 1rem;
  font-weight: 500;
  font-size: 0.92em;
}

.action-block.date-event,
.action-block.receipt-event,
.action-block.start-event,
.action-blockNaNpxinder-event,
.action-block.creation-event,
.action-block.date-reminder,
.action-block.date-confirm-reminder,
.action-block.receipt-reminder,
.action-block.confirm-event {
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--black-60);
  padding: 0.5rem 1rem;
  text-align: center;
}

.action-block span {
  position: relative;
}

.empty-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25rem;
}

.empty-list-container span {
  font-weight: 500;
  margin-top: 1.875rem;
}

.action-block.date-event span,
.action-block.receipt-event span,
.action-block.start-event span,
.action-blockNaNpxinder-event span,
.action-block.creation-event span,
.action-block.date-reminder span,
.action-block.date-confirm-reminder span,
.action-block.receipt-reminder span,
.action-block.confirm-event span {
  padding-left: 1.375rem;
}

.action-block.date-event span:before,
.action-block.confirm-event span:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  background: url("../icons/calendar--gray.svg") no-repeat;
  background-size: contain;
}

.action-block.date-reminder span:before,
.action-block.date-confirm-reminder span:before,
.action-block.receipt-reminder span:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  background: url("../icons/reminder--gray.svg") no-repeat;
  background-size: contain;
}

.action-block.creation-event span:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0;
  width: 0.875rem;
  height: 0.875rem;
  background: url("../icons/plus--gray.svg") no-repeat;
  background-size: contain;
}

.action-block.start-event span:before {
  content: "";
  position: absolute;
  top: 0.125rem;
  left: -0.125rem;
  width: 1.125rem;
  height: 1.125rem;
  background: url("../icons/launch--grey.svg") no-repeat;
  background-size: contain;
}

.action-block.receipt-event span:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  background: url("../icons/order--gray.svg") no-repeat;
  background-size: contain;
}

.action-blockNaNpxinder-event span:before {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("../icons/reminder--gray.svg") no-repeat;
  background-size: contain;
}

.action-sender-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  height: 7.1875rem;
  width: 100%;
  border-top: 0.0625rem solid var(--black-15);
}

.action-sender-input {
  width: 85%;
  padding: 1.25rem 0 1.25rem 1.25rem;
  height: 100%;
}

.action-sender-input textarea {
  background: var(--black-15);
  border: 0;
  border-radius: 1rem;
  width: 100%;
  padding: 0.625rem 0.9375rem;
  height: 100%;
  resize: none;
}

.action-sender-button {
  width: 15%;
  padding: 1.875rem 0.9375rem 1.875rem 0.9375rem;
  height: 100%;
}

.sender-button {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 1rem;
  position: relative;
  background: var(--white-100);
  transition: all 0.3s;

  &::before {
    content: "";
    position: absolute;
    top: 1.125rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1.375rem;
    height: 1.375rem;
    background: url("../icons/send--blue.svg") no-repeat;
    background-size: contain;
  }

  &:hover {
    background: var(--blue-5);
  }
}

.order-portal {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.portal-header {
  height: 6.25rem;
  border-bottom: 0.0625rem solid var(--black-15);
  padding: 0 1.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portal-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portal-title img {
  width: 3.125rem;
  margin-right: 1.25rem;
}

.portal-phone {
  border: 0.0625rem solid var(--black-15);
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.9375rem 0 2.8125rem;
  border-radius: 1rem;
  color: var(--black-100);
  transition: all 0.3s;
  position: relative;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    left: 0.9375rem;
    top: 0.5625rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url("../icons/phone--black.svg") no-repeat;
    background-size: contain;
    transition: all 0.3s;
  }

  &:hover {
    text-decoration: none;
    color: var(--blue-100);
    background: var(--blue-5);
    border: 0.0625rem solid var(--blue-40);
  }
}

.portal-phone:hover:before {
  background: url("../icons/phone--blue.svg") no-repeat;
  background-size: contain;
}

.waiting-order {
  height: 3.75rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 1.125rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625rem 0 1.25rem;
}

.waiting-orders-container p {
  margin-bottom: 10px;
}

.waiting-order .no-border,
.waiting-order.disabled .no-border {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

.provider-name {
  padding-left: 1.875rem;
  position: relative;
  font-weight: 600;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: url("../icons/order--blue.svg") no-repeat;
    background-size: contain;
  }
}

.waiting-orders-container {
  font-weight: 500;
  margin-bottom: 1.875rem;
}

.order-receipt-confirm,
.datepicker-validate,
.datepicker-delete {
  height: 2.5rem;
  border: 0.0625rem solid var(--green-170-40);
  background: var(--green-30);
  border-radius: 1rem;
  padding: 0 0.9375rem;
  color: var(--green-170);
  font-weight: 600;
  transition: all 0.3s;
}

.datepicker-delete {
  border: 0.0625rem solid var(--red-170-40);
  background: var(--red-30);
  color: var(--red-170);
  margin-top: 0.875rem;
}

.datepicker-delete:hover {
  background: var(--red-100);
  color: var(--white-100);
  border: 0.0625rem solid var(--red-100);
}

.order-receipt-confirm:hover,
.datepicker-validate:hover {
  background: var(--green-100);
  color: var(--white-100);
  border: 0.0625rem solid var(--green-100);
}

.date-edit-buttons {
  display: flex;
  width: 12.1875rem;
}

.datepicker-validate {
  margin-top: 0.875rem;
  width: 100%;
  margin-right: 0.3125rem;
}

.erased {
  color: var(--black-50);
  font-style: italic;
}

.datepicker-delete,
.waiting-order.disabled {
  width: 100%;
}

.waiting-orders-block .waiting-order.disabled {
  background: var(--black-15);
  padding: 0 1.25rem;
}

.waiting-orders-block .waiting-order.disabled span {
  color: var(--black-60);
}

.waiting-order.disabled .provider-name:before {
  background: url("../icons/order--gray.svg") no-repeat;
  background-size: contain;
}

.waiting-order-warning {
  background: var(--blue-5);
  color: var(--blue-100);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.9375rem;
  border-radius: 1rem;
  font-size: 0.9em;
}

.view-container .waiting-order-warning {
  margin-bottom: 1.875rem;
}

.waiting-order-warning p {
  margin: 0;
  color: var(--blue-120);
  font-weight: 500;
  font-size: 1.1em;
}

.delivery-date-container {
  padding: 0.875rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 1.125rem;
  font-weight: 500;
}

.delivery-date-title {
  padding-left: 2.1875rem;
  position: relative;
}

.delivery-date-title:before {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.375rem;
  height: 1.375rem;
  background: url("../icons/shipping--blue.svg") no-repeat;
  background-size: contain;
}

.warning-delivery {
  color: var(--red-100);
}

.delivery-container {
  min-height: 26px;
  display: flex;
}

.datepicker-block {
  width: 12.5rem;
  display: flex;
  position: relative;
}

.datepicker-block .react-datepicker__input-container {
  height: 100%;
}

.datepicker-block .estimation-button,
.estimated-date {
  height: 100%;
  width: calc(100% - 0.3125rem);
  background: var(--blue-100);
  border: 0;
  border-radius: 0.75rem 0.125rem 0.125rem 0.75rem;
  color: white;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  caret-color: transparent;
  text-align: center;
  padding-top: 2.875rem;
  font-weight: 600;
  font-size: 1.1em;
}

.datepicker-block .estimation-button:disabled {
  background: var(--black-50);
  cursor: not-allowed;
}

.datepicker-block .estimation-button:disabled:hover {
  background: var(--black-50);
}

.estimated-date-edit {
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;
  padding: 0.3125rem;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 0.25rem;
  transition: all 0.2s;
}

.estimated-date-edit:hover {
  cursor: pointer;
  background: var(--blue-60);
}

.estimated-date h1 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 0;
}

.estimated-date h4 {
  font-size: 1.1em;
  font-weight: 600;
}

.datepicker-block .estimated-date {
  padding-top: 0.375rem;
}

.datepicker-block .estimation-button:hover {
  background: var(--blue-120);
}

.datepicker-block .estimation-button::placeholder,
.datepicker-block .estimation-button::target-text {
  font-weight: 600;
  font-size: 1.1em;
  color: white;
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.datepicker-image {
  position: absolute;
  z-index: 9;
  top: 20%;
  left: calc(50% - 0.3125rem);
  transform: translate(-50%, 0);
  cursor: pointer;
}

.datepicker-block .estimation-button img {
  margin-bottom: 10px;
}

.address-block {
  width: calc(100% - 12.5rem);
  background: var(--blue-5);
  border-radius: 0.125rem 0.75rem 0.75rem 0.125rem;
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0.625rem 0.625rem 1.5625rem;
}

.address-block span {
  margin-bottom: 0.125rem;
}

.address-block .delivery {
  font-weight: 600;
  color: var(--blue-120);
}

.address-block .provider-title {
  font-weight: bold;
  font-size: 1.3em;
}

.address-block .provider-address {
  font-weight: 600;
}

.order-status-bar-container {
  display: flex;
  align-items: center;
}

.order-status-item {
  background: var(--black-15);
  border-radius: 10px;
  padding: 0.125rem 0.5rem 0.125rem 1.625rem;
  margin-right: 0.375rem;
  position: relative;
}

.order-status-item.delivered {
  background: var(--green-30);
}

.order-status-item.delivered .order-status-tip {
  border: 0.0625rem solid var(--green-100);
  background: var(--green-100);
}

.order-status-item.delivered .order-status-tip:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.6875rem;
  height: 0.5rem;
  transform: translate(-50%, -50%);
  background: url("../icons/save--white.svg") no-repeat;
  background-size: contain;
}

.order-status-item.in-progress {
  background: var(--yellow-30);
}

.order-status-item.in-progress .order-status-tip {
  border: 0.0625rem solid var(--yellow-100);
}

.order-status-item.difficulty {
  background: var(--red-30);
}

.order-status-item.difficulty .order-status-tip {
  border: 0.0625rem solid var(--red-100);
  background: var(--red-100);
}

.order-status-item.difficulty .order-status-tip:before {
  content: "!";
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-status-item .order-status-tip {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0.25rem;
  width: 1.125rem;
  height: 1.125rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 0.4375rem;
}

.order-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-404 h1 {
  margin-top: 1.25rem;
}

.custom-filter {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--blue-20);
  height: 100%;
  width: 21.875rem;
  z-index: 99;
  transition: all ease-in-out 0.3s;
}

.custom-filter.closed {
  right: -21.875rem;
}

.page-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.custom-filter-header .close-button {
  background: var(--blue-100);
  border: 0;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1rem;
  position: relative;
  transition: all 0.3s;
}

.custom-filter-header .close-button:hover {
  background: var(--blue-120);
}

.custom-filter-header .close-button:before {
  content: "+";
  color: white;
  font-size: 3em;
  transform: rotate(45deg);
  position: absolute;
  top: -1.125rem;
  left: 0.3125rem;
  font-weight: 100;
}

.custom-filter-header {
  display: flex;
  align-items: center;
  padding: 1.25rem;
}

.custom-filter-header h1 {
  font-size: 1.4em;
  font-weight: 800;
  margin-left: 1rem;
  margin-bottom: 0;
}

.custom-filter .bottom-buttons {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.0625rem solid var(--blue-60);
}

.bottom-buttons .transparent-button {
  margin-left: 1.25rem;
}

.custom-filter .bottom-buttons * {
  width: 100%;
}

.transparent-button {
  background: transparent;
  border: 0;
  font-weight: 500;
  color: var(--blue-100);
  height: 2.5rem;
  transition: all 0.3s;
  border-radius: 1rem;
}

.transparent-button:hover {
  background: var(--blue-30);
}

.filter-input-container {
  padding: 0 1.25rem;
}

.custom-filter .filter-input .filter-title {
  font-weight: 600;
  display: block;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.custom-filter .input-container {
  width: 100%;
}

.filter-input {
  margin-bottom: 1.25rem;
}

.filter-input .input-date-container {
  display: flex;
  position: relative;
}

.filter-input .input-date-container:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.0625rem;
  background: var(--black-30);
}

.filter-input .input-date-container .classic-input {
  width: 9rem;
}

.stop-date .react-datepicker-wrapper .react-datepicker__input-container {
  display: flex;
  justify-content: flex-end;
}

.order-delivery-add-container {
  width: calc(100% - 12.5rem);
  display: flex;
  align-items: center;
}

.order-delivery-add-transition {
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
}

.customer-type .provider-content img {
  height: 2.5rem;
}

.margin-top-30 {
  margin-top: 1.875rem;
}

.final-delivery-confirm {
  margin-top: 1.25rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-component {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form-container {
  padding: 10px;
  text-align: left;
}

.sigCanvas {
  border: 0.0625rem solid #c6c6c6;
  border-radius: 1rem;
  width: 100%;
  min-height: 9.375rem;
}

.erase-signature {
  position: absolute;
  top: 0.5rem;
  right: 0.375rem;
}

.erase-signature button {
  border-radius: 0.4375rem;
}

.signature-container {
  position: relative;
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-signature {
  position: absolute;
  left: 0.375rem;
  top: 0.4375rem;
}

.transactions-container {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.transactions-container .transaction-block {
  border-bottom: 0.0625rem solid var(--black-15);
  padding: 1.25rem 1.25rem 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transactions-container .transaction-title {
  font-weight: 500;
}

.transactions-container .transaction-title div:first-child {
  display: flex;
  flex-direction: column;
}

.transactions-container .transaction-titles {
  display: flex;
  flex-direction: column;
}

.transactions-container .transaction-titles span:first-child {
  font-weight: 600;
}

.transactions-container .discreet {
  color: var(--black-30);
  font-size: 0.9em;
}

.transactions-container .MuiGrid-item {
  display: flex;
  align-items: center;
}

.transactions-container.clients {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.container {
  padding: 1.875rem 1.875rem 0 1.875rem;
  width: 100% !important;
}

.transactions-header {
  padding: 0.625rem 1.25rem;
  background: var(--blue-5);
}

.transactions-header span {
  font-weight: 600;
  font-size: 0.9em;
  color: var(--black-70);
}

.form-container h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1.875rem;
}

.transaction-block.gocardless-block {
  background: var(--black-15);
  flex-direction: column;
  align-items: unset;
}

.transaction-titles.gocardless {
  padding-left: 1.25rem;
  position: relative;
}

.transaction-titles.gocardless:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.25rem;
  height: calc(100%);
  background: var(--blue-100);
  border-radius: 0.25rem 0.25rem 0 0;
}

.transaction-titles.gocardless:after {
  content: "";
  position: absolute;
  top: calc(50% - 0.375rem);
  left: -0.25rem;
  width: 0.75rem;
  height: 0.75rem;
  background: var(--blue-100);
  border-radius: 0.375rem;
}

.invoice-finder-select {
  border: 0.0625rem solid var(--black-15);
  border-radius: 0.5rem;
  height: 1.875rem;
  padding: 0 0.375rem;
  width: 15rem;
  cursor: pointer;
}

#transactions-importer .invoice-finder-select {
  width: 100%;
  height: 2.5rem;
}

.gocardless-invoice-block {
  margin-bottom: 1rem;
}

.transaction-title.gocardless {
  padding-left: 1.25rem;
  position: relative;
}

.gocardless-invoice-block .MuiGrid-item:first-child {
  position: relative;
}

.gocardless-invoice-block:last-child .MuiGrid-item:first-child:before {
  height: 100%;
}

.gocardless-invoice-block .MuiGrid-item:first-child:before {
  content: "";
  position: absolute;
  top: -1rem;
  left: 1rem;
  width: 0.25rem;
  height: calc(100% + 1.25rem);
  background: var(--blue-100);
  border-radius: 0.25rem;
}

.transaction-title.gocardless:after {
  content: "";
  position: absolute;
  top: calc(50% - 0.3125rem);
  left: -0.1875rem;
  width: 10px;
  height: 10px;
  background: var(--blue-100);
  border-radius: 0.375rem;
  z-index: 9;
}

.gocardless-invoice-block:last-child {
  margin-bottom: 0;
}

.main-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--white-100);
  transform: translate(-50%, -50%);
  padding: 1.25rem;
  border-radius: 1rem;
  min-width: 37.5rem;
}

.main-modal .invoices-recap {
  max-height: 31.25rem;
  overflow-y: scroll;
  margin-bottom: 1.25rem;
}

.main-modal h5 {
  font-weight: bold;
  margin: 0.3125rem 0 1.875rem 0;
}

.invoice-recap-block {
  display: flex;
  position: relative;
}

.invoice-recap-details {
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  padding: 10px;
  position: relative;
  overflow-x: scroll;
}

.invoice-recap-details-price {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.invoice-recap-details-price span:first-child {
  max-width: 20.625rem;
}

.invoice-recap-details span:first-child {
  font-weight: 500;
}

.invoice-recap-details span {
  margin-bottom: 0.3125rem;
}

.invoice-recap-block .invoice-recap-details:first-child {
  margin-right: 2.5rem;
}

.invoice-recap-block:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(calc(-50% + 2rem), calc(-50% - 0.625rem));
  width: 2rem;
  height: 2rem;
  background: url("../icons/arrow-small-right.svg") no-repeat;
  background-size: contain;
}

.invoice-recap-block .manual {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31.25rem;
  border: 0.0625rem solid var(--black-15);
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  padding: 10px;
  font-weight: 500;
}

.invoices-recap-logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 1.875rem;
}

.bank-infos {
  position: fixed;
  bottom: 1.875rem;
  right: 1.875rem;
  display: flex;
}

.bank-infos .bank-infos-block {
  background: var(--blue-5);
  border: 0.0625rem solid var(--blue-60);
  padding: 0.625rem 0.625rem 0.3125rem 10px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 12.5rem;
  color: var(--blue-100);
}

.bank-infos .bank-infos-block.valid {
  border: 0.0625rem solid var(--green-100);
  color: var(--green-100);
}

.bank-infos .bank-infos-block.invalid {
  border: 0.0625rem solid var(--red-100);
  color: var(--red-100);
}

.bank-infos .bank-infos-block span {
  font-size: 1.4em;
  font-weight: 600;
}

.engine-data-container {
  width: 100%;
  background: var(--blue-5);
  padding: 1.25rem;
  border-radius: 1rem;
  margin-bottom: 1.875rem;
}

.engine-data-container .input-row-container:last-child {
  margin-bottom: 0;
}

.metas-file-chooser {
  background: var(--blue-5);
  padding: 1.25rem;
  width: 100%;
  border-radius: 1.25rem;
  border: 0.125rem dashed var(--blue-60);
  height: calc(50vh);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.metas-file-chooser-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.metas-file-chooser.data {
  background: var(--green-30);
  border: 0.0625rem solid var(--green-170-40);
  height: 2.5rem;
  border-radius: 1rem;
  margin-bottom: 1.875rem;
}

.metas-file-chooser.data span {
  margin-top: 0;
  transform: translate(0, -3.125rem);
  transition: all 0.5s ease-in-out;
}

.metas-file-chooser.data img {
  opacity: 0;
}

.metas-file-chooser.data .metas-file-chooser-input {
  opacity: 0;
}

.metas-file-chooser .metas-file-chooser-input {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.metas-file-chooser input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.metas-file-chooser span {
  margin-top: 1.25rem;
  font-weight: 500;
}

.importer-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.875rem 0;
}

.importer-loader h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 0;
}

.importer-info {
  background: var(--blue-5);
  width: 100%;
  padding: 0.625rem 0.9375rem;
  border: 0.0625rem solid var(--blue-60);
  border-radius: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  margin-bottom: 10px;
}

.importer-info.success {
  background: var(--green-30);
  border: 0.0625rem solid var(--green-100);
}

.importer-info.fail {
  background: var(--red-30);
  border: 0.0625rem solid var(--red-100);
  flex-direction: column;
  align-items: flex-start;
}

.importer-error-button-container {
  padding: 1.25rem 0;
  display: flex;
  justify-content: flex-end;
}

.list-container.non-selectable thead th:first-child {
  padding-left: 3.375rem;
}

.list-container.non-selectable .column-icon:first-child:before {
  left: 1.875rem;
}

.list-container.non-selectable tbody tr td:first-child {
  padding-left: 1.875rem;
}

.metas-invoices-list .list-container {
  margin-top: 1.875rem;
  height: unset;
  border-radius: 1rem;
  border: 0.0625rem solid var(--black-15);
}

.error-span {
  font-weight: 800;
  color: var(--red-170);
}

strong {
  font-weight: 600;
}

.invoice-option-select {
  display: flex;
  flex-direction: column;
  background: var(--blue-20);
  color: var(--blue-120);
  border-radius: 0.5rem;
  margin-right: 10px;
  padding: 10px;
  width: 100%;
}

.invoice-option-select.exact-match {
  background: var(--green-30);
  color: var(--green-170);
}

.invoice-option-select-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-option-select-infos div:first-child {
  display: flex;
  flex-direction: column;
}

.invoice-option-select-infos div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.invoice-option-select .invoice-number {
  font-weight: bold;
}

.invoice-option-select .invoice-customer {
  font-size: 0.8em;
  border-top: 0.0625rem solid var(--black-30);
  padding-top: 0.375rem;
  margin-top: 0.375rem;
}

.invoice-option-select .invoice-total,
.invoice-option-select .invoice-balance {
  font-weight: bold;
  font-size: 1.2em;
}

.transactions-list .transaction-block .MuiGrid-item {
  padding-top: 0.5rem;
}

.float-right {
  float: right;
}

.MuiCircularProgress-svg {
  color: var(--blue-100);
}

.white-loader .MuiCircularProgress-svg {
  color: var(--white-100);
}

.css-1jqq78o-placeholder {
  font-size: clamp(0.7rem, 0.6429rem + 0.2857vw, 0.9rem);
}

#root .MuiCircularProgress-root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#multi-select.MuiFormControl-root {
  width: auto;
}

#multi-select .MuiInputBase-root,
body .MuiInputBase-formControl {
  border-radius: 10px;
  background-color: var(--white-100);
}

#demo-multiple-chip-label {
  transform: translate(0.875rem, 0.5rem) scale(1);
  color: #000000;
}

.MuiChip-root.MuiChip-filled {
  height: 1.375rem !important;
  margin: 0.3125rem 0 0.3125rem 0.3125rem;
  border-radius: 0.5rem !important;
}

#demo-multiple-chip-label.Mui-focused,
#demo-multiple-chip-label.MuiFormLabel-filled {
  transform: translate(0.875rem, -0.5625rem) scale(0.75);
  background: var(--white-100);
  padding: 0 0.625rem 0 0.3125rem;
}

#multi-select .MuiInputBase-root .MuiSelect-select {
  height: 1.875rem;
  padding: 0.3125rem;

  input {
    &:focus {
      outline: none;
    }
  }
}

#multi-select .MuiInputBase-root .MuiSvgIcon-root {
  display: none;
}

#multi-select .MuiInputBase-root .MuiSelect-nativeInput:after {
  content: "";
  position: absolute;
  right: 0.75rem;
  top: 1rem;
  width: 0.9375rem;
  height: 1.375rem;
  background: url("../../assets/icons/arrow-toggle.svg") no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.test-content {
  font-weight: 500;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test-content .test-duration {
  margin: 1.25rem 0;
}

.test-content .test-form {
  max-width: 40.625rem;
  margin-top: 1.875rem;
}

.test-content .flex-50 {
  display: flex;
  align-items: center;
}

.test-content .flex-50 div {
  width: 100%;
}

.test-content .test-submit {
  width: 100%;
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.test-content .test-duration-details {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-30);
  border-radius: 1rem;
  padding: 1.25rem;
}

.portal-title span {
  color: var(--black-15);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container.extendable {
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.75fr;
  grid-gap: 2.5rem;
}

.input-container.no-search {
  grid-template-columns: 1fr 0.75fr 1fr;
}

.input-container.no-search .single-search-input {
  grid-area: 1/3;
}

.input-datepicker {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.analytics-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  border-radius: 1rem;
}

.analytics-row.is_big {
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--black-10);
  color: var(--black-60);
  width: 100%;
}

.analytics-row.is_big a {
  text-decoration: none;
  color: var(--blue-100);
  transition: 0.3s ease-in;
}

.analytics-row.is_big a:hover {
  text-decoration: none;
  color: var(--blue-120);
}

.analytics-row.is_big p {
  margin: 0;
  padding: 8px 12px;
}

.table-row {
  margin: 1.875rem 0;
}

.ads-container {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.ads-container .transactions-container {
  margin-bottom: 0;
}

.ads-row {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.ads-row.small {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1.25rem;
}

.gap {
  display: flex;
  flex-direction: row !important;
  align-items: stretch;

  div {
    width: 100%;
    border-radius: 1rem;
  }
}

.card_inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.9375rem;
}

.card_inline:last-child {
  margin-bottom: 0;
}

.row_inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
}

.c-line {
  height: 0.0625rem;
  width: 100%;
  margin: 0.625rem 0;
  background-color: #dedede;
}

.row_inline .c-card_data {
  font-size: 24px !important;
}

.sample .c-card {
  background-color: #f5f5f5;
}

.c-card {
  padding: 1.125rem 1.25rem;
  height: 100%;
  border-radius: 1rem;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  background-color: var(--white-100);
  position: relative;
}

.c-card.small-card,
.c-card.initial {
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.05);
  padding: 1.25rem;
  background-color: var(--white-100);
  border-radius: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 1.25rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease;
}

.c-card.small-card img,
.c-card.initial img {
  width: 100px;
  height: 100px;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
  border: 0.0938rem solid var(--black-15);
  pointer-events: none;
}

.c-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 1.25rem;
}

.c-card.small-card .c-card_data,
.c-card.initial .c-card_data {
  border-left: 0.125rem solid var(--blue-110);
  padding-left: 10px;
  font-size: 1.375rem;
  font-weight: 900;
  color: var(--blue-110);
}

.c-card_tooltip {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}

.c-card .divider {
  height: 0.0625rem;
  width: 100%;
  margin: 0.875rem 0;
  background: var(--black-10);
}

.c-card .c-card_title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue-110);
}

.c-card.small-card .c-card_title {
  margin-block: 0;
}

.c-card .c-card_data {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  line-height: 32px;
  font-weight: 900;
  color: var(--blue-110);
}

.c-card .c-card_data.is_small {
  font-size: 28.8px;
  font-weight: bold;
  margin: 0;
  line-height: 32px;
}

.c-tooltip {
  background: #f8f8f8;
  border: 0.0625rem solid #dedede;
  border-radius: 100%;
  padding: 0.3125rem;
  cursor: pointer;
}

.c-tooltip>path {
  fill: #b7b7b7;
}

.c-card_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.9375rem;
}

.c-card_top.livie {
  justify-content: flex-start;
}

.c-row_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 0.25rem;
}

.c-column {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.refer {
  font-size: 12.8px;
  font-weight: 600;
  color: var(--black-50);
}

.refer strong {
  color: var(--black-100);
}

.pourcent {
  border-radius: 10px;
  padding: 0.1875rem 0.5rem;
  font-weight: bold;
  font-size: 0.9em;
}

.pourcent.positive {
  background: var(--green-30);
  color: var(--green-170);
}

.pourcent.negative {
  background: #fdd1c9;
  color: #9f3a1e;
}

.pourcent.neutral {
  background: #f5f5f5;
  color: #757575;
}

.period-select {
  width: 14.375rem;
}

.main-app.full-width {
  height: unset;
}

.customer-info .c-tooltip {
  margin: 0 0.25rem;
}

.waiting-order-warning.customer-info {
  width: 100%;
  justify-content: flex-start;
}

.period-select .select__input {
  color: transparent !important;
}

.MuiButtonBase-root.save-dates {
  transition: all 0.5s;
  opacity: 0;
  padding: 0.5rem 0;
}

.MuiButtonBase-root.save-dates.visible {
  opacity: 1;
  padding: 0.5rem;
  margin-left: 10px;
}

.input-datepicker .save-dates .MuiSvgIcon-root {
  transition: all 0.2s;
  width: 0;
}

.input-datepicker .save-dates.visible .MuiSvgIcon-root {
  width: 1.5rem;
}

.title-with-button {
  display: flex;
  margin-bottom: 1.875rem;
  align-items: center;
  justify-content: space-between;
}

.title-with-button h2 {
  margin-bottom: 0;
}

.admin-select-bar .searchbar-container {
  width: fit-content;
}

.global-amount-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;
}

.global-amount-charts {
  border-radius: 1rem;
  height: 100%;
  display: flex;
  padding: 1.25rem;
  align-items: center;
  justify-content: space-around;
  background-color: var(--white-100);
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.05);
}

.custom-doughnut-container {
  width: 13.75rem;
  text-align: center;
}

.custom-doughnut-container .doughnut-title {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.card_inline.donuts {
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 10px;
    transform: translateY(-50%);
  }
}

.card_inline.donuts.pink:before {
  background-color: #ff6384ff;
}

.card_inline.donuts.blue:before {
  background-color: var(--blue-100);
}

.card_inline.donuts.yellow:before {
  background-color: #ffcd56ff;
}

.card_inline.donuts.green:before {
  background-color: var(--green-100);
}

.flex-center {
  display: flex;
  justify-content: center;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  border-radius: initial !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 10px !important;
  margin-top: 10px !important;
  box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.05) !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--blue-100) !important;
  transition: all 0.3s !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  transition: all 0.3s !important;
}

/* === Media Query === */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1280px) {
  .ads-row.small {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .c-section {
    padding-bottom: 15px;
  }

  .analytics-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 810px) {
  .portal-header {
    padding: 0 1.25rem;
  }

  .portal-title h1 {
    font-size: 1.2em;
  }

  .portal-title span,
  .portal-phone {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ads-row {
    flex-direction: column;
  }

  .ads-row.small {
    grid-template-columns: repeat(2, 1fr);
  }

  .global-amount-container {
    display: flex;
    flex-direction: column;
  }

  .row_inline .c-card_data {
    font-size: 1rem !important;
    text-wrap: nowrap;
  }

  hr {
    display: none;
  }
}

@media screen and (max-width: 425px) {

  .c-card.small-card img,
  .c-card.initial img {
    display: none;
  }

  .analytics-row {
    grid-template-columns: 1fr;
  }

  table {
    tbody {
      tr {
        td {
          padding: 0.625rem;

          div {
            text-wrap: nowrap;
          }
        }
      }
    }
  }

  .pagination-container {
    .current-page-info {
      display: none;
    }

    .rows-by-page {
      span {
        display: none;
      }
    }
  }

  #multi-select.MuiFormControl-root {
    bottom: 69px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 999;
  }

  .header-title {
    p {
      padding-left: 0;
    }
  }

  .ads-row.small {
    grid-template-columns: 1fr;
  }
}