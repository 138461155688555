/* === Property List Style === */

.cards-container {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  .property-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid var(--blue-100);
    background-color: var(--blue-100);
    border-radius: 24px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.2s ease-in-out;
    p {
      margin-bottom: 0rem;
    }
    &:hover {
      box-shadow: rgba(11, 43, 158, 0.5) 0px 0px 20px -6px;
    }
    .property-card-header {
      padding: 15px 20px;
      color: var(--white-100);
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      p {
        hyphens: auto;
        &:nth-child(2) {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          padding: 5px;
        }
      }
    }
    .card-ga_type {
      background-color: var(--white-100);
      padding: 20px;
      border-radius: 16px;
      color: var(--blue-100);
      display: flex;
      flex-direction: column;
      font-weight: bold;
      .custom-tag {
        margin-top: 10px;
      }
      .property-card-link {
        text-decoration: none;
        color: inherit;
        width: fit-content;
        position: absolute;
        bottom: 0;
        right: -1px;
        border: 1px solid var(--blue-100);
        border-radius: 50% 0% 0% 50%;
        padding: 5px;
        transition: all 0.3s;
        &:hover {
          background-color: var(--blue-100);
          svg {
            color: var(--white-100);
          }
        }
        svg {
          padding: 5px;
          color: var(--blue-100);
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
}
