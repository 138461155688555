.gestion-hotel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  .gestion-hotel-row {
    width: 80rem;
    margin: 0 auto;
    height: fit-content;
    gap: 1.25rem;
    .c-card.hotel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .c-card-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;
        .property-hotel {
          font-style: italic;
          color: var(--blue-100);
        }
        p {
          font-weight: 900;
        }
        .c-card-content-wrapper-image {
          background-color: var(--black-5);
          border: 1px solid var(--black-15);
          height: 125px;
          width: 125px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
          pointer-events: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .c-card_hotel_content-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.25rem;
      width: 100%;
      .c-card_hotel-content {
        background-color: var(--black-5);
        border-radius: 1rem;
        border: 1px solid var(--black-15);
        padding: 1.25rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .c-card_hotel-img {
          width: 100%;
          object-fit: cover;
          border: none;
        }
      }
    }
  }
  .gestion-hotel-invoices {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 1.875rem;
    .gestion-heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .btn-contact {
        display: flex;
        height: 44px;
        padding: 0px 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: var(--white-100);
        border-radius: 10px;
        border: 1px solid var(--black-15);
        text-decoration: none;
        color: var(--black-100);
        font-weight: bold;
        transition: all 0.3s;
        &:hover {
          background-color: var(--black-10);
          border: 1px solid var(--black-15);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .gestion-hotel-row, .c-card_hotel_content-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
