.tab-container {
  display: flex;
  gap: 15px;
  padding: 15px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 20px;
  background-color: var(--white-100);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.1);
  .tab-container-left {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    text-wrap: nowrap;
    a {
      text-decoration: none;
    }
    .tab-link {
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
      align-items: center;
      padding: 8px 10px;
      border-radius: 12px;
      color: var(--black-100);
      background-color: var(--white-100);
      border: 1px solid var(--black-15);
      transition: all 0.3s;
      background-color: var(--black-10);
      span {
        color: var(--black-100);
      }
      img {
        filter: invert(1);
      }
      &:hover {
        transform: translateY(-1.5px);
      }
    }
    .tab-active {
      background-color: var(--blue-100);
      border: 1px solid var(--blue-100);
      span {
        color: var(--white-100);
      }
      img {
        filter: invert(0);
      }
    }
  }
  #button-export {
    display: flex;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid var(--black-15);
    transition: all 0.3s;
    background-color: var(--black-10);
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
    &:hover {
      transform: translateY(-2.5px);
    }
  }

  hr {
    width: 2px;
    height: 25px;
    background-color: var(--black-100);
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .tab-container {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1440px) {
  #button-export {
    display: none;
  }
}
