.main-login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  .login-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/login-background.png") no-repeat center center / cover;
    z-index: -1;
  }

  .login-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.05);

    .login-left {
      padding: 35px;
      background: var(--blue-100);
      color: var(--white-100);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      img {
        width: 100%;
        max-height: 60vh;
        max-height: 60dvh;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
        pointer-events: none;
      }

      p {
        opacity: 0.5;
        font-size: 0.9rem;
        font-style: italic;
        text-align: center;
      }
    }

    .login-form {
      padding: 35px;
      background: var(--white-100);
      border: 1px solid var(--black-10);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4rem;

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 170px;
          object-fit: cover;
        }
      }

      .login-form-right {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h1 {
          font-size: clamp(1.25rem, 1rem + 1.25vw, 2rem);
          font-weight: bold;
          text-align: center;
        }

        .welcome-message {
          font-size: 0.9rem;
          color: var(--blue-100);
          text-align: center;
        }

        .login-input-group {
          display: flex;
          flex-direction: column;
          gap: 12px;

          label {
            font-size: 0.9rem;
            font-weight: 500;

            .required {
              color: var(--red-100);
            }
          }

          input {
            border: 1px solid var(--black-15);
            background: var(--black-10);
            border-radius: 10px;
            height: 45px;
            padding: 0 16px;

            &::placeholder {
              font-size: 0.9rem;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .forgot-password {
          font-size: 0.9rem;
          color: var(--black-70);
          text-decoration: none;
          width: fit-content;

          &:hover {
            opacity: 0.5;
          }
        }

        .login-error {
          background: var(--red-100);
          color: var(--white-100);
          padding: 8px 12px;
          border-radius: 10px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .login-button-container {
          display: flex;
          justify-content: center;

          .btn.btn-primary.login-button {
            border-radius: 10px;
            background: var(--blue-100);
            font-weight: 800;
            height: 40px;
            text-transform: uppercase;
            font-size: 0.9rem;
            padding: 0 45px 0 20px;
            position: relative;
            border: 0;

            &:hover {
              background: var(--blue-120);
            }

            &::after {
              content: "";
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              aspect-ratio: 1/1;
              background: url("../../assets/icons/login.svg") no-repeat;
              background-size: contain;
            }
          }

          .btn.btn-primary.login-button-loading {
            border-radius: 10px;
            background: var(--blue-100);
            font-weight: 800;
            height: 40px;
            text-transform: uppercase;
            font-size: 0.9rem;
            padding: 0 20px;
            position: relative;
            border: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .login-container {
      grid-template-columns: 1fr;
      width: 90vw;
      height: fit-content;
      max-height: 100vh;
      max-height: 100dvh;
      overflow: hidden;

      .login-left {
        display: none;
      }

      .login-form {
        padding: 1.5rem;
        justify-content: space-between;

        .img-container {
          img {
            width: 140px;
            object-fit: cover;
          }
        }

        .login-form-right {
          gap: 1.25rem;
        }
      }
    }
  }
}

.login-message {
  background: var(--green-100);
  color: var(--white-100);
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}