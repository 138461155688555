:root {
  --blue-5: #f1f8fd;
  --blue-20: #e4f1fc;
  --blue-30: #cde6f9;
  --blue-40: #c3e1f8;
  --blue-60: #95c9f3;
  --blue-100: #3b61e1;
  --blue-110: #1b35ab;
  --blue-120: rgba(59, 97, 225, 0.8);

  --red-100: #e97e63;
  --red-70: #ff8d78;
  --red-30: #fdd1c9;
  --red-170-40: rgba(159, 58, 30, 0.4);
  --red-170: #9f3a1e;

  --yellow-100: #f3ab1b;
  --yellow-30: #fbe4b6;
  --yellow-170-40: rgba(153, 102, 0, 0.4);
  --yellow-170: #996600;

  --green-100: #2fb163;
  --green-70: #95dba6;
  --green-30: #c4edce;
  --green-170-40: rgba(40, 113, 69, 0.4);
  --green-170: #287145;

  --purple-100: #7147c2;
  --purple-30: #e4d6ff;
  --purple-60: #ac94db;
  --purple-120: #5935a0;

  --orange-100: #f36e1b;
  --orange-30: #ffe0cc;
  --orange-60: #f9b58b;
  --orange-120: #da5b0b;

  --black-100: #212121;
  --white-100: #ffffff;
  --white-100-10: rgba(255, 255, 255, 0.1);
  --white-100-20: rgba(255, 255, 255, 0.2);
  --white-100-50: rgba(255, 255, 255, 0.5);
  --black-5: #fbfbfb;
  --black-10: #f5f5f5;
  --black-intermediate: #e5e5e5;
  --black-15: #dedede;
  --black-30: #bfbfbf;
  --black-50: #a1a1a1;
  --black-60: #8a8a8a;
  --black-70: #666666;

  --menu-size: 13.75rem;

  --primary-font: "Manrope", sans-serif;
}

* {
  font-family: var(--primary-font);
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-block: 0;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

p {
  margin: 0px;
}