/* === Livie Style === */

.tab-livie {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  .section-livie-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .article-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: fit-content;
      gap: 1.25rem;
      #nb_token {
        grid-template-columns: 1fr;
        #nb_tokens_article {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          gap: 1rem;
          .c-card-data_percentage {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            .c-progress-bar {
              position: relative;
              height: 30px;
              progress {
                -webkit-appearance: none;
                appearance: none;
                width: 100%;
                height: 100%;
                color: var(--blue-110);
                border-radius: 8px;
                background-color: var(--black-10);
                &::-webkit-progress-bar {
                  background-color: var(--blue-100);
                  border-radius: 8px;
                }
                &::-webkit-progress-value {
                  background-color: var(--blue-110);
                  border-radius: 8px;
                }
                &::-moz-progress-bar {
                  background-color: var(--blue-110);
                  border-radius: 8px;
                }
              }

              span {
                position: absolute;
                left: 8px;
                top: 50%;
                transform: translateY(-50%);
                line-height: 30px;
                color: var(--white-100);
                font-weight: bold;
                font-size: 0.9rem;
              }
            }

            label {
              display: block;
              margin-top: 8px;
              border-left: 2px solid var(--blue-110);
              padding-left: 0.625rem;
              font-size: 1.25rem;
              font-weight: 900;
              color: var(--blue-110);
            }
          }
        }
      }
    }
  }
}
.table-wrapper-livie {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media screen and (max-width: 1280px) {
  .tab-livie {
    .article-wrapper {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .tab-livie {
    .article-wrapper {
      grid-template-columns: 1fr !important;
    }
  }
}

.view-conversation {
  width: fit-content;
  display: flex;
  float: right;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  background: rgba(59, 97, 225, 0.9);
  backdrop-filter: blur(7.5px);
  color: var(--white-100);
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.12px;
  transition: all 0.3s;
  &:hover {
    background-color: var(--blue-110);
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  .ReactModal__Content {
    border-radius: 18px !important;
    height: fit-content;
    width: fit-content;
    padding: 0px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.modal-livie-wrapper {
  width: 310px;
  height: 480px;
  overflow: hidden;
  .modal-livie-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    align-items: center;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.04) 0%,
        rgba(0, 0, 0, 0.04) 100%
      ),
      var(--white-100);
    box-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.15);
    .modal-livie-header-icon {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.15px;
      }
    }
    button {
      border: none;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--blue-100);
        img {
          filter: invert(1);
        }
      }
    }
  }
}
.modal-livie-content {
  display: flex;
  padding: 20px 12px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: auto;
  height: calc(480px - 49px);
  .modal-livie-text-ai {
    display: flex;
    padding-right: 40px;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    p {
      padding: 10px 12px;
      border-radius: 15px 15px 15px 5px;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.06) 0%,
          rgba(0, 0, 0, 0.06) 100%
        ),
        #fff;
    }
  }
  .modal-livie-text-users {
    margin-left: 50px;
    display: flex;
    padding: 9px 12px 10px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 15px 15px 5px 15px;
    background: var(--blue-100);
    p {
      color: var(--white-100);
    }
  }
}

.conversation-cell {
  width: 30vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
